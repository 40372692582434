<template>
  <div @click.stop="pickerclick">
    <section v-if="btnitr === '0'" class="multi-platform-payment">
      <div class="top-bar bg-white">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
          <el-form-item label="姓名">
            <el-input v-model="formInline.leasorName" placeholder="请输入姓名" style="width: 150px"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="formInline.leasorPhone" placeholder="请输入手机号" style="width: 150px"></el-input>
          </el-form-item>

          <el-form-item label="开票类型">
            <el-select ref="typeRef" v-model="formInline.type" placeholder=请选择开票类型 style="width: 150px">
              <el-option label="全部" value=""></el-option>
              <el-option label="收据" value="700001"></el-option>
              <el-option label="普票" value="700002"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="抬头类型">
            <el-select ref="titleTypeRef" v-model="formInline.titleType" placeholder="请选择抬头类型" style="width: 150px">
              <el-option label="全部" value=""></el-option>
              <el-option label="单位" value="2"></el-option>
              <el-option label="个人" value="1"></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="活动区域">-->
          <!--            <el-select v-model="formInline.region" placeholder="活动区域" style="width: 150px">-->
          <!--              <el-option label="区域一" value="shanghai"></el-option>-->
          <!--              <el-option label="区域二" value="beijing"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item label="发票抬头">
            <el-input v-model="formInline.invoiceRise" placeholder="请输入发票抬头" style="width: 150px"></el-input>
          </el-form-item>
          <el-form-item label="房源名称">
            <el-input v-model="formInline.apartmentName" placeholder="请输入房源名称" style="width: 150px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="primary" @click="onSubmitinvoice">审核</el-button>
            <el-button type="primary" @click="onSubmitreset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <r-e-table
          el-table-column
          @selection-change="myhandleSelectionChange"
          class="bg-white"
          ref="orderTableRef"
          :dataRequest="getLogs"
          :columns="tableColumn"
          :query="formInline"
          :height="780">
        <template slot="empty">
          <el-empty/>
        </template>

        <el-table-column slot="toolbar" label="操作" width="120">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="examineanxiangqing(row)">详情</span>
              <!--              v-if="getButtonMenu('SHUILVSHEZHI')"-->
              <span class="table-btn" v-if="row.status === '待审核'  && getButtonMenu('SHEN_PI')"
                    @click="examinean(row)">审核</span>
              <span class="table-btn" v-if="row.status === '审核通过'" @click="billingStatuslook(row)">查看</span>
            </div>
          </template>
        </el-table-column>
      </r-e-table>
    </section>
    <!--    2024-1-2-详情  -->
    <particulars v-if="btnitr === '1'" ref="parref"></particulars>
    <dialogExamineands :rowlist="rowlist" ref="indexdialong" @shuaxin="shuaxin"
                       itemtitle="确认批量通过申请吗？"></dialogExamineands>
  </div>
</template>
<script>
import particulars from './components/particulars.vue'
import {tableColumn} from "./data";
import dialogExamineands from '@/views/bill-management/Invoice-review/components/dialog-examineands.vue'
import {billingStatuslookapi, communityAllrecoredt, Invoicereview} from "@/api/community-management";
import RETable from "@/components/Gloabl/r-e-table/index.vue";
import {buttonCookies} from "@storage/cookies";

export default {
  name: "shortmessage-log",
  data() {
    return {
      rowlist: [],
      btnitr: '0',
      tableColumn: tableColumn(this),
      formInline: {
        leasorName: '',
        leasorPhone: '',
        invoiceRise: '',
        titleType: '',
        type: ''
      }
    };
  },
  components: {
    RETable,
    particulars,
    dialogExamineands
  },
  mounted() {

  },
  methods: {
    // 接口事件
    getLogs(params) {
      return communityAllrecoredt(params);
    },
    // 点击查询事件
    onSubmit() {
      this.$refs["orderTableRef"].getTableData();
      console.log('submit!');
    },
    // 点击重置
    onSubmitreset() {
      this.formInline = {};
      Promise.resolve(this.formInline).then(() => this.onSubmit());
    },
    // 刷新
    shuaxin() {
      setTimeout(() => {
        Promise.resolve(this.formInline).then(() => this.onSubmit());
      }, 1000)
    },
    // 审核--多个
    onSubmitinvoice() {
      if (this.rowlist.length === 0) {
        this.$message({
          type: 'error',
          message: '请先勾选数据'
        });
      } else {
        this.$refs.indexdialong.openinvoiceclick()
        console.log(this.rowlist)

      }

    },
    xiangQing(row) {
      console.log(row)
    },
    // 详情
    examineanxiangqing(e) {

      Invoicereview(e.uuid).then(res => {
        // 陈守亮-2024-1-10-标记
        let {info} = res
        console.log(info, '我是请求数据')
        if (info) {
          this.btnitr = '1'
          setTimeout(() => {
            this.$refs.parref.newmylistobjtow(info);

          }, 600)
        }
        console.log(info)
      })
    },
    // 表格审核
    examinean(e) {
      Invoicereview(e.uuid).then(res => {
        let {info} = res
        console.log(info)
        if (info) {
          this.btnitr = '1'
          setTimeout(() => {
            this.$refs.parref.newmylistobj(info);
          }, 600)
        }
      })
    },
    // 批量审核事件
    myhandleSelectionChange(row) {
      console.log(row)
      this.rowlist = row.map(item => {
        return item.uuid
      })
    },
    //获取按钮权限数组
    getButtonMenu(menu) {
      return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
    },
    billingStatuslook(row) {
      console.log(row)
      billingStatuslookapi({uuid: row.uuid}).then(res => {
        console.log(res)
        let baseurl = res.msg
        window.open(baseurl, "_blank");
      })
    },
    // 2024-4-18-新增点击空白关闭组件
    pickerclick(e) {
      this.$refs.typeRef.blur()
      this.$refs.titleTypeRef.blur()
      this.$refs.typeRef.blur()
    },
  },
  watch: {
    // 签约时间
    SigningDate(value) {
      if (value && value.length !== 0) {
        let [signDt1, signDt2] = value;
        this.formSearch.startDate = signDt1;
        this.formSearch.endDate = signDt2;
      } else {
        this.formSearch.startDate = null;
        this.formSearch.endDate = null;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.multi-platform-payment {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: flex-start;
  }

  /deep/ .r-e-table {
    .el-table .cell {
      /*white-space: nowrap;*/
      text-align: center;
    }
  }
}
</style>