<template>
<div class="box_particuars">
  <el-button size="mini" style="margin-left: 90%;margin-bottom: 10px" @click="btnfanhui">返回</el-button>
<!--  租户信息-->
   <div class="box_rligr">
     <div style="height: 15px"></div>
     <div >
       <span class="title_img">·</span>
       <span>租户信息</span>
     </div>
     <div class="box_item" style="">
      <div class="box_item_flex">租户姓名：{{row.invoice.leasor.name}}</div>
      <div class="box_item_flex">租户电话：{{row.invoice.leasor.tel1}}</div>
      <div class="box_item_flex">身份证：{{row.invoice.leasor.idcard}}</div>
      <div class="box_item_flex">合同起止：{{row.invoice.payBill.contract.startDate}} -- {{row.invoice.payBill.contract.endDate}}</div>
     </div>
     <div style="height: 15px"></div>

   </div>
  <!--  租户信息-->
  <div class="box_rligr">
    <div style="height: 15px"></div>
    <div >
      <span class="title_img">·</span>
      <span>账单信息</span>
    </div>
    <div class="box_item">
     <div class="box_item_flex">账单编号：{{row.invoice.payBill.billNum}}</div>
     <div class="box_item_flex">账单类型：{{row.invoice.payBill.typeName}}</div>
     <div class="box_item_flex">缴费状态：{{row.invoice.payBill.statusCode}}</div>
     <div class="box_item_flex">支付渠道：{{row.invoice.payBill.paymentChannel}}</div>
    </div>
    <div class="box_item">
     <div class="box_item_flex">合同编号：{{row.invoice.payBill.contract.contractNum}}</div>
     <div class="box_item_flex">账单周期：{{row.invoice.payBill.startDate}}至{{row.invoice.payBill.endDate}}</div>
     <div class="box_item_flex">账单金额：{{row.payBillDepositVo.billAmount }}</div>
     <div class="box_item_flex">已付金额：{{row.payBillDepositVo.paidAmount}}</div>
    </div>
    <div class="box_item">
      <div class="box_item_flex">未付金额：{{row.payBillDepositVo.noPaidAmount}}</div>
     <div class="box_item_flex">付款时间：{{row.payBillDepositVo.paymentDetails[0].paidDate}}</div>


    </div>
    <div class="box_item">
     <div class="box_item_flex" style="width: 500px">备注：{{row.invoice.payBill.comment}}</div>

    </div>
    <div style="height: 15px"></div>

  </div>
  <!--  租户信息-->
  <div class="box_rligr">
    <div style="height: 15px"></div>
    <div >
      <span class="title_img">·</span>
      <span>房源信息</span>
    </div>
    <div class="box_item">
     <div class="box_item_flex">小区名称：{{row.invoice.apartment.community.name}}</div>
     <div class="box_item_flex">座落：{{row.invoice.apartment.name}}</div>
     <div class="box_item_flex">所属 区域：{{row.invoice.apartment.community.district}}</div>
    </div>
    <div class="box_item">
   <span>住房类型：长租公寓</span>
    <div class="box_item_flex">户型：{{row.invoice.apartment.apartmentType}}</div>
    <div class="box_item_flex">整套面积：{{row.invoice.apartment.outsideArea}}</div>
  </div>
    <div style="height: 15px"></div>
  </div>
  <!--  租户信息-->
  <div class="box_rligr" v-if="typeNameitr">
    <div style="height: 15px"></div>
    <div >
      <span class="title_img">·</span>
      <span>抬头信息</span>
    </div>
    <div class="box_item">
      <div class="box_item_flex">抬头类型：{{row.invoice.invoiceTitle.type === 1 ? '个人'  :'单位'}}</div>
      <div class="box_item_flex">发票抬头：{{row.invoice.invoiceTitle.invoiceRise}}</div>
      <div class="box_item_flex">申请时间：{{row.invoice.billingDate}}</div>
    </div>
    <div style="height: 15px"></div>
  </div>
  <!--  租户信息-->
  <div class="box_rligr" v-if="typeNameitr">
    <div style="height: 15px"></div>
    <div >
      <span class="title_img">·</span>
      <span>审核信息</span>
    </div>
    <div class="box_item">
      <div class="box_item_flex">审核类型：{{row.invoice.status}}</div>
      <div class="box_item_flex">操作人：{{row.invoice.userName}} </div>
      <div class="box_item_flex">审核时间： {{timeFormat(new Date(   row.invoice.examineData ), "yyyy-MM-dd")}}</div>
    </div>
    <div style="height: 15px"></div>
  </div>
  <div class="box_btn">
    <el-button v-if="qwer"  type="primary"  size="small" @click="submitclick('add')">通过</el-button>
    <el-button v-if="qwer" type="danger" size="small" plain @click="submitclick('err')">拒绝</el-button>
    <el-button type="primary" size="small" plain  @click="btnfanhui">取消</el-button>
  </div>
  <!--    审批组件-->
  <dialogExamineandapprove @btnfanhui="btnfanhui" :itemtitle="itemtitle" :uuid="row.uuid" :status="status" ref="dialogref"></dialogExamineandapprove>
</div>
</template>

<script>


import dialogExamineandapprove from "@/views/bill-management/Invoice-review/components/dialog-examineand.vue";
import {numberFormat, timeFormat} from "@custom";

export default {
  components: {dialogExamineandapprove},
  data() {
    return {
      typeNameitr:false,
      qwer:false,
      itemtitle:'确认通过申请吗',
      row:null,
      status:''
    }
  },
  methods:{
    timeFormat,

    newmylistobj(row){
      row.invoice.payBill.contract.startDate = timeFormat(new Date(row.invoice.payBill.contract.startDate), "yyyy-MM-dd ");
      row.invoice.payBill.contract.endDate = timeFormat(new Date(row.invoice.payBill.contract.startDate), "yyyy-MM-dd");
      row.invoice.payBill.startDate = timeFormat(new Date(row.invoice.payBill.startDate), "yyyy-MM-dd");
      row.invoice.payBill.endDate =  timeFormat(new Date(row.invoice.payBill.endDate), "yyyy-MM-dd");
      row.payBillDepositVo.paymentDetails[0].paidDate = timeFormat(new Date(row.payBillDepositVo.paymentDetails[0].paidDate), "yyyy-MM-dd");
      if(row.invoice.payBill.statusCode === 600602 ) row.invoice.payBill.statusCode = "已缴费"
      if(row.invoice.payBill.statusCode === 600601 ) row.invoice.payBill.statusCode = "待处理"
      if(row.invoice.payBill.statusCode === 600603 ) row.invoice.payBill.statusCode = "已逾期"
      if(row.invoice.payBill.statusCode === 600604 ) row.invoice.payBill.statusCode = "已作废"
      row.payBillDepositVo.billAmount  =  numberFormat(row.payBillDepositVo.billAmount / 100);
      row.payBillDepositVo.paidAmount = numberFormat(row.payBillDepositVo.paidAmount / 100);
      row.payBillDepositVo.noPaidAmount = numberFormat(row.payBillDepositVo.noPaidAmount / 100);
      if(row.invoice.typeName === '普票'){
        this.typeNameitr = true
        row.invoice.billingDate =  timeFormat(new Date(  row.invoice.billingDate ), "yyyy-MM-dd");
        // row.invoice.examineData === null ? null : timeFormat(new Date(   row.invoice.examineData ), "yyyy-MM-dd");
      }
      this.qwer = true
      this.row = row
      console.log('111',this.row,this.qwer)
    },
    newmylistobjtow(row){
      // console.log(row)
      row.invoice.payBill.contract.startDate = timeFormat(new Date(row.invoice.payBill.contract.startDate), "yyyy-MM-dd ");
      row.invoice.payBill.contract.endDate = timeFormat(new Date(row.invoice.payBill.contract.startDate), "yyyy-MM-dd");
      row.invoice.payBill.startDate = timeFormat(new Date(row.invoice.payBill.startDate), "yyyy-MM-dd");
      row.invoice.payBill.endDate =  timeFormat(new Date(row.invoice.payBill.endDate), "yyyy-MM-dd");
      if(row.invoice.payBill.statusCode === 600602 ) row.invoice.payBill.statusCode = "已缴费"
      if(row.invoice.payBill.statusCode === 600601 ) row.invoice.payBill.statusCode = "待处理"
      if(row.invoice.payBill.statusCode === 600603 ) row.invoice.payBill.statusCode = "已逾期"
      if(row.invoice.payBill.statusCode === 600604 ) row.invoice.payBill.statusCode = "已作废"
      row.payBillDepositVo.paymentDetails[0].paidDate = timeFormat(new Date(row.payBillDepositVo.paymentDetails[0].paidDate), "yyyy-MM-dd");
      row.payBillDepositVo.billAmount  =  numberFormat(row.payBillDepositVo.billAmount / 100);
      row.payBillDepositVo.paidAmount = numberFormat(row.payBillDepositVo.paidAmount / 100);
      row.payBillDepositVo.noPaidAmount = numberFormat(row.payBillDepositVo.noPaidAmount / 100);

      if(row.invoice.typeName === '普票'){
        this.typeNameitr = true
        row.invoice.billingDate =  timeFormat(new Date(  row.invoice.billingDate ), "yyyy-MM-dd");
        // row.invoice.examineData === null ? null : timeFormat(new Date(row.invoice.examineData ), "yyyy-MM-dd");
      }
      this.qwer = false
      this.row = row
      console.log('222',this.row,this.qwer)
    },
    btnfanhui(){
      this.row = null
      this.qwer = false
      this.typeNameitr = false
      this.$parent.btnitr = '0'
    },
    submitclick(e){
      if(!this.row.uuid) this.row.uuid =  this.row.invoice.uuid
      console.log(this.row)
      if(e === 'err') {
        this.itemtitle = '确定要拒绝该申请吗' ;  this.status = 2;
      }
      if(e === 'add') {
        this.itemtitle = '确认通过申请吗';this.status = 1;
      }
      if(e === 'adds') {
        this.itemtitle = '确定要批量通过该申请吗'; this.status = 1;
      }
      // if(e !== 'adds') {
      //   this.$parent.btnitr = '1';alert('no')
      // }
      this.$refs.dialogref.openinvoiceclick()
    }
  }
}
</script>

<style scoped lang="scss">
.box_particuars{
  margin-top: 20px;
}
.box_rligr{
  width: 97%;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.title_img{
  width: 20px;
  height: 80px;
  background-color: #249dde;
  color: #249dde;
  margin-left: 10px;
}
.box_item{
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  display: flex
  //span{
  //  //width: 800px;
  //  margin-right: 300px;
  //  color: #606266;
  //}
}
.box_item_flex{
  width: 400px;
}
.box_btn{
  display: flex;
  justify-content: center;

}
</style>